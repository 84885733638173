export const MESSAGES = {
  UnauthorizedException: 'Credenciais inválidas',
  'Admin already exists': 'Admin já existe',
  'property id should not exist': 'ID não deveria existir',
};

export const GET_ERROR = (message) => {
  if (MESSAGES[message]) {
    return MESSAGES[message];
  } else {
    return 'Erro desconhecido, Tente novamente mais tarde';
  }
};
