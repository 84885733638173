import styled from 'styled-components';
import {variant} from 'styled-system';
import tokens from 'assets/styles/tokens';

export const TableContent = styled.div`
  overflow: auto;
  border-radius: 8px;
  border: 1px solid transparent;
  position: relative;
  margin-bottom: 20px;
`;
export const FilterContainer = styled.div`
  display: flex;
`;
export const FilterSelect = styled.div`
  width: 300px;
  margin-left: 10px;
`;
export const Header = styled.div`
  padding-top: ${tokens.spacing.lg};
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
`;
export const Title = styled('div')(
  {
    fontSize: '20px',
    lineHeight: '24px',
    fontFamily: tokens.fonts.bold,
    color: tokens.colors.black01,
    fontWeight: 700,
  },
  variant({
    prop: 'color',
    variants: {
      red: {
        color: tokens.colors.black01,
      },
    },
  }),
);
