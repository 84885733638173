/* eslint-disable no-unused-vars */
import React, {useRef, useMemo} from 'react';
import * as yup from 'yup';

import SideModal from 'components/core/SideModal';
import {useDispatch, useSelector} from 'react-redux';
import {AdminActions} from 'store/admin/admin.ducks';
import {isPending, isReject} from 'lib/core/request';
import * as S from '../admin.styles';
import Form from '../Form';

const EditAdmin = ({collapse, callBack, data}) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const {adminRequests, error} = useSelector(({admin}) => admin);

  const clear = () => {
    formRef.current.reset();
    callBack(false);
    dispatch(
      AdminActions.listAdmin({
        name: '',
        limit: 10 * 2,
        page: 1,
      }),
    );
  };
  const submit = (object) => {
    const send = {id: data.id, ...object};
    dispatch(AdminActions.updateAdmin(send, clear));
  };
  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().required('Campo nome obrigatório'),
        password: yup.string(),
        email: yup
          .string()
          .required('Campo de e-mail obrigatório')
          .email('Digite um e-mail válido'),
      }),
    [],
  );

  return (
    <SideModal collapse={collapse} callBack={callBack}>
      <S.Content>
        <S.Header>Editar administrador</S.Header>
        {collapse && (
          <Form
            validationSchema={validationSchema}
            refForm={formRef}
            submitAction={submit}
            isPending={isPending(adminRequests.UPDATE_ADMIN)}
            isReject={isReject(adminRequests.UPDATE_ADMIN)}
            error={error}
            buttonText="Editar administrador"
            admin={data}
          />
        )}
      </S.Content>
    </SideModal>
  );
};
EditAdmin.defaultProps = {
  collapse: false,
  callBack: () => {},
};
export default EditAdmin;
