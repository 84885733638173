import React from 'react';
import * as S from './CardInfo.styles';

const ColorText = ({children, color}) => {
  return <S.ColorText color={color}>{children}</S.ColorText>;
};
ColorText.defaultProps = {
  color: null,
};
export default ColorText;
