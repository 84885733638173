import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import GlobalStyle from 'components/structure/GlobalStyle';
import {store} from 'store/store';
import Routes from 'constants/routes';
import {ToastProvider} from 'react-toast-notifications';
import ToastMessage from 'components/core/ToastMessage';

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <ToastProvider>
      <Routes />
      <ToastMessage />
    </ToastProvider>
  </Provider>,
  document.getElementById('root'),
);
