import React from 'react';
import {NavbarDefault} from 'components/core/navbar';
import {Container} from 'components/structure/Container';
import {Content} from 'components/structure/Content';
import TableRating from 'components/contexts/ratings/TableRatings';

const Ratings = () => {
  return (
    <>
      <NavbarDefault active="ratings" />
      <Content>
        <Container>
          <TableRating />
        </Container>
      </Content>
    </>
  );
};

export default Ratings;
