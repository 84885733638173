import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const Form = styled.form`
  transition: all 0.4s;
`;

export const FormGroup = styled.div`
  margin-top: ${tokens.spacing.lg};
  margin-bottom: ${tokens.spacing.lg};
`;
