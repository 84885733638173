import React, {useEffect} from 'react';
import {NavbarDefault} from 'components/core/navbar';
import {Container} from 'components/structure/Container';
import {Content} from 'components/structure/Content';
import {useDispatch, useSelector} from 'react-redux';
import {getStars} from 'lib/core/utilityView';
import {useParams} from 'react-router';
import {Row} from 'components/structure/Row';
import {Col} from 'components/structure/Col';
import {history} from 'constants/routes';
import IconEmail from 'assets/icons/icEmail.svg';
import IconPhone from 'assets/icons/icPhone.svg';
import IconCalendar from 'assets/icons/icCalendar.svg';
import IconLocation from 'assets/icons/icLocation.svg';
import {
  CardProfile,
  CardProfileData,
  CardProfileDataContent,
  CardProfileImage,
  CardProfileInfo,
} from 'components/core/CardProfile';
import {CardDescription, CardInfo, CardTitle} from 'components/core/CardInfo';
import LoadingFullScreen from 'components/core/LoadingFullScreen';
import {isPending} from 'lib/core/request';
import Card from 'components/core/Card';
import * as S from './Show.styles';
import {getAvatar} from 'lib/contexts/users';
import {formatDate} from 'lib/core/utility';
import {BussinessActions} from 'store/bussiness/bussiness.ducks';
import Button from 'components/core/Button';
import {REQUEST_RESOLVED} from 'constants/request';
import urls from 'constants/urls';
import TableBussinessContacts from 'components/contexts/bussiness/TableBussinessContacts';

const Bussiness = () => {
  const {id} = useParams();
  const dispatch = useDispatch();

  const {data, bussinessRequests} = useSelector(({bussiness}) => bussiness);

  useEffect(() => {
    dispatch(BussinessActions.getBussinessById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (bussinessRequests.ACTIVATE_BUSSINESS === REQUEST_RESOLVED) {
      dispatch(BussinessActions.getBussinessById(id));
    }
  }, [bussinessRequests.ACTIVATE_BUSSINESS, dispatch, id]);

  useEffect(() => {
    console.log(bussinessRequests);
    if (bussinessRequests.DELETE_BUSSINESS_BY_ID === REQUEST_RESOLVED) {
      history.push(urls.LINKS.BUSSINESS);
    }
  }, [
    bussinessRequests,
    bussinessRequests.DELETE_BUSSINESS_BY_ID,
    dispatch,
    id,
  ]);

  return (
    <>
      {isPending(bussinessRequests.GET_BUSSINESS_BY_ID) && (
        <LoadingFullScreen />
      )}
      {isPending(bussinessRequests.ACTIVATE_BUSSINESS) && <LoadingFullScreen />}
      {isPending(bussinessRequests.DELETE_BUSSINESS_BY_ID) && (
        <LoadingFullScreen />
      )}
      <NavbarDefault active="bussiness" />
      <Content>
        <Container>
          {data && (
            <Row>
              <Col desktop="4" mobile="12">
                <CardProfile>
                  <CardProfileImage src={getAvatar(data)}>
                    {data.name}
                    <CardTitle fontSize="24px">
                      {getStars(data.avgRating)}
                    </CardTitle>
                  </CardProfileImage>
                  <CardProfileInfo color={data.isActive ? 'green' : 'red'}>
                    {data.isActive ? 'Ativo' : 'Desativado'}
                  </CardProfileInfo>
                  <CardProfileDataContent>
                    <CardProfileData icon={IconEmail} name="OAB">
                      {data.oab ? data.oab : 'Não informado.'}
                    </CardProfileData>
                    <CardProfileData icon={IconEmail} name="Email">
                      {data.email}
                    </CardProfileData>
                    <CardProfileData icon={IconPhone} name="Celular">
                      {data.phone}
                    </CardProfileData>
                    <CardProfileData icon={IconCalendar} name="Ativo em">
                      {formatDate(data.createAt, 'dd/mm/yy HH:MM')}
                    </CardProfileData>
                    <CardProfileData icon={IconLocation} name="Cidade">
                      {data.city.name} - {data.city.prefixState}
                    </CardProfileData>
                    <CardProfileData icon={IconLocation} name="Rua">
                      {data.street} - {data.number}
                    </CardProfileData>
                  </CardProfileDataContent>
                </CardProfile>
                <Row>
                  <Col desktop="6" mobile="12">
                    <CardProfile>
                      <CardTitle fontSize="24px">{data.amountVisits}</CardTitle>
                      <CardDescription>Visitas</CardDescription>
                    </CardProfile>
                  </Col>
                  <Col desktop="6" mobile="12">
                    <CardProfile>
                      <CardTitle fontSize="24px">
                        {data.amountContacts}
                      </CardTitle>
                      <CardDescription>Contactos</CardDescription>
                    </CardProfile>
                  </Col>
                </Row>
                <Row>
                  <Col desktop="12" mobile="12">
                    <CardProfile>
                      <Button
                        type="button"
                        color={data.isActive ? 'red' : 'green'}
                        padding="14px 14px"
                        onClick={() => {
                          dispatch(
                            BussinessActions.activateBussiness({
                              params: id,
                              data: {isActive: !data.isActive},
                            }),
                          );
                        }}>
                        {data.isActive ? 'Desativar conta' : 'Ativar conta'}
                      </Button>
                    </CardProfile>
                  </Col>
                </Row>
                <Row>
                  <Col desktop="12" mobile="12">
                    <CardProfile>
                      <Button
                        type="button"
                        color="red"
                        padding="14px 14px"
                        onClick={() => {
                          dispatch(BussinessActions.deleteBussinessById(id));
                        }}>
                        Excluir conta
                      </Button>
                    </CardProfile>
                  </Col>
                </Row>
              </Col>
              <Col desktop="8" mobile="12" style={{paddingTop: 10}}>
                <Row>
                  <Col desktop="4" mobile="12">
                    <CardInfo>
                      <CardTitle fontSize="24px">
                        {getStars(data.attendanceRating)}
                      </CardTitle>
                      <CardDescription>Atendimento</CardDescription>
                    </CardInfo>
                  </Col>
                  <Col desktop="4" mobile="12">
                    <CardInfo>
                      <CardTitle fontSize="24px">
                        {getStars(data.serviceRating)}
                      </CardTitle>
                      <CardDescription>Serviço</CardDescription>
                    </CardInfo>
                  </Col>
                  <Col desktop="4" mobile="12">
                    <CardInfo>
                      <CardTitle fontSize="24px">
                        {getStars(data.communicationRating)}
                      </CardTitle>
                      <CardDescription>Comunicação</CardDescription>
                    </CardInfo>
                  </Col>
                </Row>
                <Row>
                  <Col desktop="12">
                    <Card>
                      <S.Content>
                        <S.SubHeader>
                          <S.Title>Descrição</S.Title>
                        </S.SubHeader>
                        {data.description}
                      </S.Content>
                    </Card>
                  </Col>
                </Row>
                {data.bussinessServices.map((service) => {
                  return (
                    <Row key={service.id}>
                      <Col desktop="12">
                        <Card>
                          <S.Content>
                            <S.SubHeader>
                              <S.Title>Serviços</S.Title>
                            </S.SubHeader>
                            <S.SubHeader>
                              <S.Title>Nome do serviço</S.Title>
                            </S.SubHeader>
                            {service.name}
                            <br />
                            <br />
                            <S.SubHeader>
                              <S.Title>Especialidade</S.Title>
                            </S.SubHeader>
                            <S.SpecialityInfo color={'orange'}>
                              {service.specialty.name}
                            </S.SpecialityInfo>
                            <br />
                            <br />
                            <S.SubHeader>
                              <S.Title>Descrição</S.Title>
                            </S.SubHeader>
                            {service.description}
                          </S.Content>
                        </Card>
                      </Col>
                    </Row>
                  );
                })}

                <Row>
                  <TableBussinessContacts data={data.bussinessContacts} />
                </Row>
              </Col>
            </Row>
          )}
        </Container>
      </Content>
    </>
  );
};

export default Bussiness;
