import React, {useCallback, useState} from 'react';
import Table from 'components/core/Table';
import * as S from './TableAdmin.styles';
import {useDispatch, useSelector} from 'react-redux';
import CellTable from './CellAvatar/CellAvatar';

import {Search} from 'components/core/form/Search';
import LoadingFullScreen from 'components/core/LoadingFullScreen/';
import {isPending} from 'lib/core/request';
import {history} from 'constants/routes';
import urls from 'constants/urls';
import {AdminActions} from 'store/admin/admin.ducks';
import SelectInput from 'components/core/form/SelectInput';
import {VscAdd} from 'react-icons/vsc';
import {VscEdit} from 'react-icons/vsc';
import {VscTrash} from 'react-icons/vsc';
import Button from 'components/core/Button';
import NewAdmin from '../NewAdmin';
import EditAdmin from '../EditAdmin';
import DeleteAdmin from '../DeleteAdmin';
import styled from 'styled-components';

export const CellCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableAdmin = () => {
  const [modalOpen, setModalOpen] = useState(null);
  const [adminItem, setAdminItem] = useState(null);

  const setModal = useCallback(
    (modal) => {
      if (modal === modalOpen) {
        setModalOpen(null);
      } else {
        setModalOpen(modal);
      }
    },
    [setModalOpen, modalOpen],
  );

  const dispatch = useDispatch();
  const closeModal = () => {
    setModalOpen(null);
  };
  const setEditAdmin = useCallback(
    (admin) => {
      setAdminItem(admin);
      setModal('EditAdmin');
    },
    [setAdminItem, setModal],
  );

  const setDeleteAdmin = useCallback(
    (admin) => {
      setAdminItem(admin);
      setModal('DeleteAdmin');
    },
    [setAdminItem, setModal],
  );
  const {
    listAdmin: {
      items,
      meta: {totalPages},
    },
    adminRequests,
  } = useSelector(({admin}) => admin);
  const onChangeSearch = (e) => {
    dispatch(
      AdminActions.listAdmin({
        name: e.target.value,
        limit: 10 * 2,
        page: 1,
      }),
    );
  };
  const options = [
    {
      label: 'Filtrar por status',
      value: '',
    },
    {
      label: 'Ativados',
      value: true,
    },
    {
      label: 'Pendentes',
      value: false,
    },
  ];

  const fetchData = useCallback(
    ({pageSize, pageIndex}) => {
      dispatch(
        AdminActions.listAdmin({
          limit: pageSize * 2,
          page: pageIndex + 1,
        }),
      );
    },
    [dispatch],
  );
  const onChangeStatus = (e) => {
    dispatch(
      AdminActions.listAdmin({
        isActive: e.target.value,
        limit: 10,
        page: 1,
      }),
    );
  };

  const onClickRow = (row) => {
    const {id} = row;
    history.push(urls.LINKS.USERS(id));
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
        minWidth: 400,
        Cell: (props) => <CellTable {...props} onClick={onClickRow} />,
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 450,
      },
      {
        Header: 'Ações',
        accessor: 'action',
        minWidth: 50,
        Cell: (props) => {
          const data = props.cell.row.original;
          return (
            <CellCenter>
              <VscEdit
                style={{
                  width: '24px',
                  height: '24px',
                  cursor: 'pointer',
                  marginLeft: '4px',
                }}
                onClick={() => setEditAdmin(data)}
              />
              <VscTrash
                style={{
                  width: '24px',
                  height: '24px',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={() => setDeleteAdmin(data)}
              />
            </CellCenter>
          );
        },
      },
    ],
    [setEditAdmin, setDeleteAdmin],
  );

  return (
    <S.TableContent>
      {isPending(adminRequests.LIST_ADMIN) && <LoadingFullScreen />}
      <S.Header>
        <S.Title> Todos as administradores </S.Title>
        <S.FilterContainer>
          <Search
            placeholder="Buscar administradores"
            onChange={onChangeSearch}
          />
          <S.FilterSelect>
            <SelectInput options={options} onChange={onChangeStatus} />
          </S.FilterSelect>
          <Button
            color="primary"
            width="160px"
            padding="4px 4px"
            onClick={() => {
              setModal('NewAdmin');
            }}
            style={{marginLeft: '16px'}}>
            <div
              style={{display: 'flex', alignItems: 'center', fontSize: '16px'}}>
              <VscAdd
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '10px',
                  cursor: 'pointer',
                  marginLeft: '4px',
                }}
                onClick={() => {}}
              />
              Novo
            </div>
          </Button>
        </S.FilterContainer>
      </S.Header>
      <Table
        columns={columns}
        data={items}
        pageCount={totalPages}
        fetchData={fetchData}
        showTitle="administradores"
      />
      <EditAdmin
        collapse={modalOpen === 'EditAdmin'}
        data={adminItem}
        callBack={closeModal}
      />
      <NewAdmin collapse={modalOpen === 'NewAdmin'} callBack={closeModal} />
      <DeleteAdmin
        collapse={modalOpen === 'DeleteAdmin'}
        callBack={closeModal}
        data={adminItem}
      />
    </S.TableContent>
  );
};

export default TableAdmin;
