import React from 'react';
import {NavbarDefault} from 'components/core/navbar';
import {Container} from 'components/structure/Container';
import {Content} from 'components/structure/Content';
import Dashboard from 'components/contexts/users/Dashboard';
import TableUsers from 'components/contexts/users/TableUsers';

const Users = () => {
  return (
    <>
      <NavbarDefault active="users" />
      <Content>
        <Container>
          <Dashboard />
          <TableUsers />
        </Container>
      </Content>
    </>
  );
};

export default Users;
