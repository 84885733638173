/* eslint-disable no-dupe-keys */
import styled from 'styled-components';
import {variant} from 'styled-system';
import tokens from 'assets/styles/tokens';

const HandleMessage = styled('div')(
  {
    borderRadius: '8px',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    padding: tokens.spacing.md,
  },
  variant({
    prop: 'type',
    variants: {
      error: {
        backgroundColor: tokens.colors.redMessage,
      },
      warning: {
        backgroundColor: '#F4E3CF',
        color: '#C96C00',
        fontWeight: '600',
      },
      success: {
        backgroundColor: tokens.colors.greenMessage,
        color: tokens.colors.greenTextMessage,
      },
    },
  }),
);

export default HandleMessage;
