import styled from 'styled-components';
// import tokens from 'assets/styles/tokens';

export const ModalDialog = styled.div`
  position: fixed;
  margin: auto;
  right: ${({fade}) => (fade ? '0px' : '-600px')};
  background-color: #fff;
  width: 500px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
`;
export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({open}) => (open ? '1000' : '-1')};
  display: ${({open}) => (open ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({open}) => (open ? '1000' : '-1')};
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: ${({open}) => (open ? '.5' : '0')};
`;
export const IconContent = styled.div`
  cursor: pointer;
  top: 50%;
  margin-left: -60px;
  position: absolute;
`;
export const ModalBody = styled.div`
  overflow: auto;
  height: 100%;
`;
