import {takeLatest, put} from 'redux-saga/effects';
import api from 'services/api';
import {
  RESOURCE_BUSSINESS,
  RESOURCE_BUSSINESS_ACTIVATE,
  RESOURCE_BUSSINESS_UPDATE,
} from 'constants/endpoints';
import {DELETE, GET, PATCH, POST} from 'constants/verbs';
import {serialize} from 'lib/core/utility';
import {BussinessActions, BussinessTypes} from './bussiness.ducks';

export function* listBussiness({params}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_BUSSINESS}/admin?${serialize(params)}`,
    });
    yield put(BussinessActions.listBussinessSuccess(response.data));
  } catch (error) {
    yield put(BussinessActions.bussinessRequestFailure(error));
  }
}

export function* getBussiness({params}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_BUSSINESS(params)}`,
    });

    yield put(BussinessActions.getBussinessSuccess(response.data));
  } catch (error) {
    yield put(BussinessActions.bussinessRequestFailure(error));
  }
}

export function* getBussinessById({payload}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_BUSSINESS}/admin/${payload}`,
    });

    yield put(BussinessActions.getBussinessByIdSuccess(response.data));
  } catch (error) {
    yield put(BussinessActions.bussinessRequestFailure(error));
  }
}

export function* deleteBussinessById({payload}) {
  try {
    yield api({
      method: DELETE,
      url: `${RESOURCE_BUSSINESS}/${payload}`,
    });

    yield put(BussinessActions.deleteBussinessByIdSuccess());
  } catch (error) {
    yield put(BussinessActions.bussinessRequestFailure(error));
  }
}

export function* activateBussiness({payload, callback}) {
  const {params, data} = payload;
  try {
    yield api({
      method: POST,
      url: `${RESOURCE_BUSSINESS_ACTIVATE(params)}`,
      data: data,
    });

    yield put(BussinessActions.activateBussinessSuccess());
    yield callback();
  } catch (error) {
    yield put(BussinessActions.bussinessRequestFailure(error));
  }
}

export function* updateBussiness({payload, callback}) {
  try {
    yield api({
      method: PATCH,
      url: `${RESOURCE_BUSSINESS_UPDATE(payload.id)}`,
      data: payload,
    });

    yield put(BussinessActions.updateBussinessSuccess());
    yield callback();
  } catch (error) {
    yield put(BussinessActions.bussinessRequestFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(BussinessTypes.LIST_BUSSINESS, listBussiness);
  yield takeLatest(BussinessTypes.GET_BUSSINESS, getBussiness);
  yield takeLatest(BussinessTypes.GET_BUSSINESS_BY_ID, getBussinessById);
  yield takeLatest(BussinessTypes.DELETE_BUSSINESS_BY_ID, deleteBussinessById);
  yield takeLatest(BussinessTypes.ACTIVATE_BUSSINESS, activateBussiness);
  yield takeLatest(BussinessTypes.UPDATE_BUSSINESS, updateBussiness);
}
