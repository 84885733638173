/* eslint-disable no-dupe-keys */
import styled from 'styled-components';
import {variant} from 'styled-system';
import tokens from 'assets/styles/tokens';

const Button = styled('button')(
  {
    width: ({width}) => width || '100%',
    textDecoration: 'none',
    fontSize: '16px',
    outline: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',

    textAlign: 'center',
    padding: ({padding}) => padding || '16px 32px',
    borderRadius: '8px',
    border: 'none',
  },
  variant({
    prop: 'color',
    variants: {
      primary: {
        backgroundColor: tokens.colors.primaryColor,
        border: `1px solid ${tokens.colors.primaryColor}`,
        color: tokens.colors.white,
        '&:hover': {
          border: `1px solid ${tokens.colors.white}14`,
          color: tokens.colors.white,
          backgroundColor: tokens.colors.black01,
        },
      },
      secondary: {
        backgroundColor: tokens.colors.white,
        color: tokens.colors.black01,
        border: `1px solid ${tokens.colors.black01}14`,
        '&:hover': {
          border: `1px solid ${tokens.colors.black01}47`,
          color: tokens.colors.black01,
          backgroundColor: tokens.colors.white,
        },
      },
      tertiary: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: tokens.colors.purpleLight,
        border: `1px solid ${tokens.colors.purpleLight}`,
        color: tokens.colors.purple,
        '&:hover': {
          border: `1px solid ${tokens.colors.black01}47`,
          color: tokens.colors.purple,
          backgroundColor: tokens.colors.white,
        },
        svg: {
          width: '16px',
          height: '16px',
          marginRight: '8px',
        },
      },
      green: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: tokens.colors.green02,
        border: `1px solid ${tokens.colors.green02}`,
        color: tokens.colors.white,
        justifyContent: 'center',
        '&:hover': {
          border: `1px solid ${tokens.colors.green02}47`,
          color: tokens.colors.green02,
          backgroundColor: tokens.colors.white,
        },
        svg: {
          width: '16px',
          height: '16px',
          marginRight: '8px',
        },
      },
      red: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: tokens.colors.red01,
        border: `1px solid ${tokens.colors.red01}`,
        color: tokens.colors.white,
        justifyContent: 'center',
        '&:hover': {
          border: `1px solid ${tokens.colors.red01}47`,
          color: tokens.colors.red01,
          backgroundColor: tokens.colors.white,
        },
        svg: {
          width: '16px',
          height: '16px',
          marginRight: '8px',
        },
      },
    },
  }),
);

export default Button;
