import {basePhoto} from 'constants/endpoints';

export const getAvatar = (item) => {
  const avatar = item?.avatar ? `${basePhoto}${item.avatar}` : '/avatar.png';
  return avatar;
};

export const getFullName = (item) => {
  return `${item?.firstName} ${item?.lastName}`;
};

export const getAvailableDays = (week) => {
  const indexWeek = [];
  const nameWeek = [];
  if (week) {
    const keys = Object.keys(week);
    keys.forEach((k, index) => {
      if (week[k].available) {
        if (index === 6) {
          indexWeek.push(0);
        } else {
          indexWeek.push(index + 1);
        }
        nameWeek.push(k);
      }
    });
  }

  return {indexWeek, nameWeek};
};

export const getAvailableTime = (time) => {
  const nameTime = [];
  if (time) {
    const keys = Object.keys(time);
    keys.forEach((k) => {
      if (time[k]) {
        nameTime.push(k);
      }
    });
  }
  return nameTime;
};
