import React from 'react';
import * as S from './NavbarLogin.styles';
import LogoIcon from 'assets/images/logo.svg';

const NavbarLogin = () => {
  return (
    <S.NavTop>
      <S.Logo src={LogoIcon} alt="Logo" />
    </S.NavTop>
  );
};

export default NavbarLogin;
