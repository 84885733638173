/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef} from 'react';
import * as S from './DropdownNav.styles';
import {ReactComponent as IconArrow} from 'assets/icons/icArrow.svg';

const DropdownNav = ({userType, username, menuItens}) => {
  const [open, setOpen] = useState(false);
  const node = useRef();

  const handleClickOutside = (e) => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);
  return (
    <S.Dropdown ref={node}>
      <S.DropButton
        onClick={() => {
          setOpen(!open);
        }}>
        <b>{userType} </b> {username}
        <S.IconContent>
          <IconArrow />
        </S.IconContent>
      </S.DropButton>
      <S.DropdownContent open={open}>
        {menuItens.map((item) => (
          <S.DropdownItem key={item.key} onClick={item.handleClick}>
            {item.name}
          </S.DropdownItem>
        ))}
      </S.DropdownContent>
    </S.Dropdown>
  );
};
DropdownNav.defaultProps = {
  userType: 'Master',
  username: '',
  menuItens: [
    {
      key: '1',
      handleClick: () => {},
      name: 'Sair',
    },
  ],
};
export default DropdownNav;
