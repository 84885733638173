import React from 'react';
import {NavbarLogin} from 'components/core/navbar';
import LoginForm from 'components/contexts/auth/LoginForm';

const Auth = () => {
  return (
    <>
      <NavbarLogin />
      <LoginForm />
    </>
  );
};

export default Auth;
