import React from 'react';
import * as S from './CardProfile.styles';

const CardProfileImage = ({src, children}) => {
  return (
    <S.AlignCenter>
      <S.Photo src={src} alt="Imagem de perfil do atleta" />
      <S.Name>{children}</S.Name>
    </S.AlignCenter>
  );
};

export default CardProfileImage;
