import styled from 'styled-components';
import {variant} from 'styled-system';
import tokens from 'assets/styles/tokens';

export const DashboardContent = styled.div`
  padding-top: ${tokens.spacing.lg};
`;

export const CardContainer = styled.div`
  padding: 10px;
`;

export const CardContent = styled.div`
  padding: 16px;
`;

export const AlignCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Name = styled('span')(
  {
    fontSize: '18px',
    lineHeight: '22px',
    color: tokens.colors.black01,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '16px',
  },
  variant({
    prop: 'color',
    variants: {
      red: {
        color: tokens.colors.red01,
      },
      orange: {
        color: tokens.colors.orange01,
      },
      green: {
        color: tokens.colors.green01,
      },
    },
  }),
);
export const ColorText = styled('span')(
  variant({
    prop: 'color',
    variants: {
      red: {
        color: tokens.colors.red01,
      },
      orange: {
        color: tokens.colors.orange01,
      },
      green: {
        color: tokens.colors.green01,
      },
    },
  }),
);
export const CardDescription = styled('h3')(
  {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#82829d',
  },
  variant({
    prop: 'color',
    variants: {
      red: {
        color: tokens.colors.red01,
      },
      orange: {
        color: tokens.colors.orange01,
      },
      green: {
        color: tokens.colors.green01,
      },
    },
  }),
);

export const Photo = styled('img')({
  width: '220px',
  height: '220px',
  borderRadius: '8px',
  objectFit: 'cover',
  marginBottom: '12px',
});

export const ProfileInfo = styled('div')(
  {
    borderRadius: '100px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    padding: '8px 12px',
    backgroundColor: `${tokens.colors.blue01}14`,
    color: tokens.colors.blue01,
  },
  variant({
    prop: 'color',
    variants: {
      red: {
        color: tokens.colors.red01,
        backgroundColor: `${tokens.colors.red01}14`,
      },
      orange: {
        color: tokens.colors.orange01,
        backgroundColor: `${tokens.colors.orange01}14`,
      },
      green: {
        color: tokens.colors.green01,
        backgroundColor: `${tokens.colors.green01}14`,
      },
      blue: {
        color: tokens.colors.blue01,
        backgroundColor: `${tokens.colors.blue01}14`,
      },
    },
  }),
);

export const CardProfileDataContent = styled('div')({
  padding: '16px 8px',
  '& button': {
    marginTop: '12px',
  },
});

export const CardProfileData = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
});

export const IconContent = styled('div')({
  background: ({bgIconColor}) => bgIconColor || tokens.colors.gray01,
  borderRadius: '8px',
  border: ({bgIconColor}) => (bgIconColor ? '1px solid #D6D9DC' : 'unset'),
  width: '32px',
  height: '32px',
  marginRight: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Icon = styled('img')({});
