import React from 'react';
import {NavbarDefault} from 'components/core/navbar';
import {Container} from 'components/structure/Container';
import {Content} from 'components/structure/Content';

const Show = () => {
  return (
    <>
      <NavbarDefault />
      <Content>
        <Container></Container>
      </Content>
    </>
  );
};

export default Show;
