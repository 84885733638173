import styled from 'styled-components';

export const Separator = styled.div`
  border-bottom: 1px solid black;
`;

export const TableContainer = styled.div`
  overflow: auto;
  position: relative;
  background-color: white;
`;

export const Table = styled.table`
  min-width: 100%;
  border-spacing: 0;
  table-layout: fixed;
  padding: 4px 22px;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(11, 0, 21, 0.04);
  thead {
    tr {
      background-color: #ffffff !important;
    }
  }
  tbody {
    border-bottom: 1px solid #eeeef0;
  }
`;
export const Tr = styled.tr`
  :last-child {
    td {
      border-bottom: 0;
    }
  }
  :nth-child(odd) {
    background-color: #f9faff;
  }
`;
export const Th = styled.th`
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #000000;
  padding: 25px 30px;
  :last-child {
    border-right: 0;
  }
`;
export const Td = styled.td`
  margin: 0;
  padding: 10px 10px;
  :last-child {
    border-right: 0;
    border-radius: 8px 0px 0px 8px;
  }
  :first-child {
    border-radius: 8px 0px 0px 8px;
  }
`;
export const Pagination = styled.div`
  display: flex;
  padding: 24px 30px;
  border-top: 1px solid #eeeef0;
  background-color: #ffffff;
  justify-content: space-between;
`;

export const Text = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-right: 2px;
`;
