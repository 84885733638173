import styled from 'styled-components';
import {variant} from 'styled-system';
import tokens from 'assets/styles/tokens';

export const DashboardContent = styled.div`
  padding-top: ${tokens.spacing.lg};
`;
export const Header = styled.div`
  padding-top: ${tokens.spacing.lg};
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
`;
export const Title = styled('div')(
  {
    fontSize: '16px',
    lineHeight: '24px',
    color: tokens.colors.black01,
    fontWeight: 'bold',
  },
  variant({
    prop: 'color',
    variants: {
      red: {
        color: tokens.colors.black01,
      },
    },
  }),
);

export const Filter = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${tokens.colors.black01};
`;
export const WrapperCard = styled.div`
  margin: -15px;
  ${tokens.media.xs} {
    margin: none;
  }
`;
export const CardContainer = styled.div``;
export const CardContent = styled.div`
  height: 124px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
export const AlignCenter = styled.div`
  align-self: center;
  display: inline-grid;
`;

export const CardTitle = styled('h1')(
  {
    fontSize: (props) => (props.fontSize ? props.fontSize : '32px'),
    lineHeight: '37px',
    color: tokens.colors.black01,
    fontWeight: 'normal',
    textAlign: 'center',
  },
  variant({
    prop: 'color',
    variants: {
      red: {
        color: tokens.colors.red01,
      },
      orange: {
        color: tokens.colors.orange01,
      },
      green: {
        color: tokens.colors.green01,
      },
      purple: {
        color: tokens.colors.purple,
      },
      blue: {
        color: tokens.colors.primaryHeaderColor,
      },
    },
  }),
);
export const ColorText = styled('span')(
  variant({
    prop: 'color',
    variants: {
      red: {
        color: tokens.colors.red01,
      },
      orange: {
        color: tokens.colors.orange01,
      },
      green: {
        color: tokens.colors.green01,
      },
      purple: {
        color: tokens.colors.purple,
      },
      blue: {
        color: tokens.colors.primaryHeaderColor,
      },
    },
  }),
);
export const CardDescription = styled('h3')(
  {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#82829d',
  },
  variant({
    prop: 'color',
    variants: {
      red: {
        color: tokens.colors.red01,
      },
      orange: {
        color: tokens.colors.orange01,
      },
      green: {
        color: tokens.colors.green01,
      },
    },
  }),
);
