import {useEffect} from 'react';
import {useToasts} from 'react-toast-notifications';
import {useSelector} from 'react-redux';

const ToastMessage = () => {
  const {addToast} = useToasts();
  const {contentText, autoClose, type, open} = useSelector(
    ({message}) => message,
  );

  useEffect(() => {
    if (open) {
      addToast(contentText, {
        appearance: type,
        autoDismiss: autoClose,
      });
    }
  }, [contentText, addToast, autoClose, type, open]);
  return null;
};

export default ToastMessage;
