/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {ReactComponent as IconClose} from 'assets/icons/icCloseRound.svg';
import * as S from './SideModal.styles';

const SideModal = ({children, collapse, callBack}) => {
  const [fade, setFade] = useState(collapse);
  useEffect(() => {
    setTimeout(() => {
      if (collapse) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
      setFade(collapse);
    }, 200);
  }, [collapse]);
  return (
    <>
      <S.ModalBackdrop open={collapse} />
      <S.Modal open={collapse}>
        <S.ModalDialog fade={fade}>
          <S.IconContent>
            <IconClose
              onClick={() => {
                setFade(!fade);
                setTimeout(() => {
                  callBack(!collapse);
                }, 200);
              }}
            />
          </S.IconContent>
          <S.ModalBody>{children}</S.ModalBody>
        </S.ModalDialog>
      </S.Modal>
    </>
  );
};
SideModal.defaultProps = {
  collapse: false,
  callBack: () => {},
  children: () => {},
};
export default SideModal;
