import styled from 'styled-components';
import tokens from 'assets/styles/tokens';
import {variant} from 'styled-system';

export const Content = styled.div`
  padding: ${tokens.spacing.lg};
`;

export const Container = styled.div`
  overflow: auto;
`;

export const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${tokens.colors.black01};
  padding-bottom: ${tokens.spacing.sm};
`;

export const Box = styled.div`
  background: ${tokens.colors.white};
  border: 1px solid #d6d9dc;
  box-sizing: border-box;
  border-radius: 8px;
  padding: ${tokens.spacing.lg};
  margin-bottom: ${tokens.spacing.lg};
`;

export const Title = styled.h2`
  font-size: 16px;
  line-height: 22px;
  color: ${tokens.colors.black01};
  font-weight: 700;
`;
export const Form = styled.form`
  transition: all 0.4s;
`;

export const FormGroup = styled.div`
  margin-top: ${tokens.spacing.lg};
  margin-bottom: ${tokens.spacing.lg};
`;

export const ButtonContainer = styled.div`
  display: grid;
  padding-top: 16px;
  grid-template-columns: 49% 49%;
  grid-gap: 10px;
`;

export const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const ActionContent = styled.div`
  display: flex;
`;
export const BoxContent = styled.div`
  padding: ${({padding}) => padding || '10px'};
`;

export const SubHeader = styled.div`
  padding: 5px 12px 5px 0px;
  display: flex;
  justify-content: space-between;
`;

export const SpecialityInfo = styled('div')(
  {
    borderRadius: '100px',
    fontWeight: 600,
    fontSize: '16px',
    display: 'inline-block',
    lineHeight: '19px',
    padding: '8px 12px',
    backgroundColor: `${tokens.colors.blue01}14`,
    color: tokens.colors.blue01,
  },
  variant({
    prop: 'color',
    variants: {
      red: {
        color: tokens.colors.red01,
        backgroundColor: `${tokens.colors.red01}14`,
      },
      orange: {
        color: tokens.colors.orange01,
        backgroundColor: `${tokens.colors.orange01}14`,
      },
      green: {
        color: tokens.colors.green01,
        backgroundColor: `${tokens.colors.green01}14`,
      },
      blue: {
        color: tokens.colors.blue01,
        backgroundColor: `${tokens.colors.blue01}14`,
      },
    },
  }),
);
