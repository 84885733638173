import React from 'react';

import {BsStar, BsStarFill} from 'react-icons/bs';

export const getStars = (number) => {
  const formatNumber = parseInt(number);

  switch (formatNumber) {
    case 1:
      return (
        <>
          <BsStarFill color="gold" />
          <BsStar color="gold" />
          <BsStar color="gold" />
          <BsStar color="gold" />
          <BsStar color="gold" />
        </>
      );
    case 2:
      return (
        <>
          <BsStarFill color="gold" />
          <BsStarFill color="gold" />
          <BsStar color="gold" />
          <BsStar color="gold" />
          <BsStar color="gold" />
        </>
      );
    case 3:
      return (
        <>
          <BsStarFill color="gold" />
          <BsStarFill color="gold" />
          <BsStarFill color="gold" />
          <BsStar color="gold" />
          <BsStar color="gold" />
        </>
      );
    case 4:
      return (
        <>
          <BsStarFill color="gold" />
          <BsStarFill color="gold" />
          <BsStarFill color="gold" />
          <BsStarFill color="gold" />
          <BsStar color="gold" />
        </>
      );
    case 5:
      return (
        <>
          <BsStarFill color="gold" />
          <BsStarFill color="gold" />
          <BsStarFill color="gold" />
          <BsStarFill color="gold" />
          <BsStarFill color="gold" />
        </>
      );
    default:
      return (
        <>
          <BsStar color="gold" />
          <BsStar color="gold" />
          <BsStar color="gold" />
          <BsStar color="gold" />
          <BsStar color="gold" />
        </>
      );
  }
};
