import React, {useCallback} from 'react';
import Table from 'components/core/Table';
import * as S from './TableUsers.styles';
import {useDispatch, useSelector} from 'react-redux';
import CellTable from './CellAvatar/CellAvatar';

import {Search} from 'components/core/form/Search';
import LoadingFullScreen from 'components/core/LoadingFullScreen/';
import {isPending} from 'lib/core/request';
import {history} from 'constants/routes';
import urls from 'constants/urls';
import {UserActions} from 'store/user/user.ducks';
import {formatDate} from 'lib/core/utility';
const TableRunners = () => {
  const dispatch = useDispatch();
  const {
    listUsers: {
      items,
      meta: {totalPages},
    },
    userRequests,
  } = useSelector(({user}) => user);

  const onChangeSearch = (e) => {
    dispatch(
      UserActions.listUsers({
        name: e.target.value,
        limit: 10,
        page: 1,
        orderBy: 'createAt',
        orderDirection: 'DESC',
      }),
    );
  };

  const fetchData = useCallback(
    ({pageSize, pageIndex}) => {
      dispatch(
        UserActions.listUsers({
          limit: pageSize,
          page: pageIndex + 1,
          orderBy: 'createAt',
          orderDirection: 'DESC',
        }),
      );
    },
    [dispatch],
  );
  const onClickRow = (row) => {
    const {id} = row;
    history.push(urls.LINKS.USERS(id));
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nome do usuário',
        accessor: 'user',
        width: 300,
        Cell: (props) => <CellTable {...props} onClick={onClickRow} />,
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 200,
      },
      {
        Header: 'Telefone',
        accessor: 'phone',
        minWidth: 150,
      },
      {
        Header: 'Cidade',
        accessor: 'city',
        minWidth: 216,
        Cell: (props) => {
          return (
            <span>
              {props.value && props.value !== 'undefined'
                ? `${props.value.name} - ${props.value.prefixState}`
                : '-'}
            </span>
          );
        },
      },
      {
        Header: 'Registrado em',
        accessor: 'createAt',
        minWidth: 150,
        Cell: ({value}) => formatDate(value, 'dd/mm/yy HH:MM'),
      },
    ],
    [],
  );

  return (
    <S.TableContent>
      {isPending(userRequests.LIST_USERS) && <LoadingFullScreen />}
      <S.Header>
        <S.Title> Todos os usuários </S.Title>
        <S.FilterContainer>
          <Search placeholder="Buscar usuários" onChange={onChangeSearch} />
        </S.FilterContainer>
      </S.Header>
      <Table
        columns={columns}
        data={items}
        pageCount={totalPages}
        fetchData={fetchData}
      />
    </S.TableContent>
  );
};

export default TableRunners;
