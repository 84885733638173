/* eslint-disable no-dupe-keys */
import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const Container = styled.div`
  width: 100%;
  ${({height}) => height && `height: ${height}px; overflow: auto;`}
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }

  ${tokens.media.xxl} {
    max-width: ${tokens.breakpoints[4]};
  }
  ${tokens.media.xl} {
    max-width: ${tokens.breakpoints[3]};
  }
  ${tokens.media.lg} {
    max-width: ${tokens.breakpoints[2]};
  }
  ${tokens.media.md} {
    max-width: ${tokens.breakpoints[1]};
  }
  ${tokens.media.xs} {
    max-width: ${tokens.breakpoints[0]};
  }
`;
