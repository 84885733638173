import React from 'react';
import {Route, Switch, Redirect} from 'react-router';
import {ConnectedRouter} from 'connected-react-router';
import {createBrowserHistory} from 'history';

import isAuthenticated from 'lib/core/isAuthenticated';
import urls from 'constants/urls';
import Auth from 'pages/auth';
import Users from 'pages/users';
import Bussiness from 'pages/bussiness';
import ShowUsers from 'pages/users/show';
import Ratings from 'pages/ratings';
import Admin from 'pages/admin';
import BusinessShow from 'pages/bussiness/show';

export const history = createBrowserHistory();
history.listen(() => {
  window.scrollTo(0, 0);
});

const PrivateRoute = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: urls.ROUTES.LOGIN,
              state: {from: props.location},
            }}
          />
        )
      }
    />
  );
};

const Routes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact path={urls.ROUTES.LOGIN} component={Auth} />
      <PrivateRoute exact path={urls.ROUTES.APP} component={Users} />
      <PrivateRoute exact path={urls.ROUTES.BUSSINESS} component={Bussiness} />
      <PrivateRoute
        exact
        path={urls.ROUTES.SHOW_BUSSINESS}
        component={BusinessShow}
      />
      <PrivateRoute exact path={urls.ROUTES.ADMIN} component={Admin} />
      <PrivateRoute exact path={urls.ROUTES.RATINGS} component={Ratings} />
      <PrivateRoute exact path={urls.ROUTES.USERS} component={ShowUsers} />
    </Switch>
  </ConnectedRouter>
);

PrivateRoute.propTypes = {};

PrivateRoute.defaultProps = {};

export default Routes;
