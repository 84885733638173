import {takeLatest, put} from 'redux-saga/effects';
import api from 'services/api';
import {RESOURCE_USERS} from 'constants/endpoints';
import {GET} from 'constants/verbs';
import {serialize} from 'lib/core/utility';
import {UserActions, UserTypes} from './user.ducks';

export function* listUsers({params}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_USERS}?${serialize(params)}`,
    });
    yield put(UserActions.listUsersSuccess(response.data));
  } catch (error) {
    yield put(UserActions.userRequestFailure(error));
  }
}

export function* getUser({params}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_USERS(params)}`,
    });

    yield put(UserActions.getUserSuccess(response.data));
  } catch (error) {
    yield put(UserActions.userRequestFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(UserTypes.LIST_USERS, listUsers);
  yield takeLatest(UserTypes.GET_USER, getUser);
}
