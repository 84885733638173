import React from 'react';
import {NavbarDefault} from 'components/core/navbar';
import {Container} from 'components/structure/Container';
import {Content} from 'components/structure/Content';
import TableBussiness from 'components/contexts/bussiness/TableBussiness';

const Bussiness = () => {
  return (
    <>
      <NavbarDefault active="bussiness" />
      <Content>
        <Container>
          <TableBussiness />
        </Container>
      </Content>
    </>
  );
};

export default Bussiness;
