import styled from 'styled-components';
// import tokens from 'assets/styles/tokens';

export const ModalDialog = styled.div`
  position: fixed;
  margin: auto;
  transform: translate(-50%, 0);
  height: ${({height}) => (height ? height : 'auto')};
  background-color: #fff;
  width: ${({width}) => (width ? width : '500px')};
  // transition: opacity linear 0.3s;
  opacity: ${({fade}) => (fade ? 1 : 0)};
  border-radius: 4px;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
`;
export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({open}) => (open ? '1000' : '-1')};
  display: ${({open}) => (open ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({open}) => (open ? '1000' : '-1')};
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: ${({open}) => (open ? '.5' : '0')};
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
`;
export const IconContent = styled.div`
  cursor: pointer;
  top: 50%;
  margin-left: -60px;
  position: absolute;
`;
export const ModalBody = styled.div`
  overflow: auto;
  height: 100%;
`;
