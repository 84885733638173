import React from 'react';
import {NavbarDefault} from 'components/core/navbar';
import {Container} from 'components/structure/Container';
import {Content} from 'components/structure/Content';
import TableAdmin from 'components/contexts/admin/TableAdmin';

const Admin = () => {
  return (
    <>
      <NavbarDefault active="admin" />
      <Content>
        <Container>
          <TableAdmin />
        </Container>
      </Content>
    </>
  );
};

export default Admin;
