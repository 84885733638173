/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useMemo} from 'react';
import * as S from './LoginForm.styles';
import Card from 'components/core/Card';
import Login from './Login';
import Forgot from './Forgot';

const LoginForm = () => {
  const [form, setForm] = useState('login');

  return (
    <S.LoginContent>
      <Card>
        {form === 'login' ? (
          <Login setForm={setForm} />
        ) : (
          <Forgot setForm={setForm} />
        )}
      </Card>
    </S.LoginContent>
  );
};

export default LoginForm;
