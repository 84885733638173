import {takeLatest, put} from 'redux-saga/effects';
import api from 'services/api';
import {RESOURCE_DASHBOARDS} from 'constants/endpoints';
import {GET} from 'constants/verbs';
import {DashboardActions, DashboardTypes} from './dashboard.ducks';

export function* getDashboards({type}) {
  try {
    const response = yield api({
      method: GET,
      url: RESOURCE_DASHBOARDS,
    });

    yield put(DashboardActions.getDashboardsSuccess(response.data));
  } catch (error) {
    yield put(DashboardActions.dashboardRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(DashboardTypes.GET_DASHBOARDS, getDashboards);
}
