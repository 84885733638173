import {takeLatest, put} from 'redux-saga/effects';
import api from 'services/api';
import {RESOURCE_RATING, RESOURCE_RATING_ACTIVATE} from 'constants/endpoints';
import {GET, POST} from 'constants/verbs';
import {serialize} from 'lib/core/utility';
import {RatingActions, RatingTypes} from './rating.ducks';
import {MessagesActions} from '../message/message.ducks';

export function* listRating({params}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_RATING}?${serialize(params)}`,
    });
    yield put(RatingActions.listRatingSuccess(response.data));
  } catch (error) {
    yield put(RatingActions.ratingRequestFailure(error));
  }
}

export function* getRating({params}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_RATING(params)}`,
    });

    yield put(RatingActions.getRatingSuccess(response.data));
  } catch (error) {
    yield put(RatingActions.ratingRequestFailure(error));
  }
}

export function* activateRating({payload, callback}) {
  const {params, data} = payload;
  try {
    yield api({
      method: POST,
      url: `${RESOURCE_RATING_ACTIVATE(params)}`,
      data: data,
    });

    yield put(RatingActions.activateRatingSuccess());
    yield callback();
  } catch (error) {
    yield put(RatingActions.ratingRequestFailure(error));
    yield put(
      MessagesActions.open({
        type: 'error',
        title: 'Avaliação ja foi aprovada!',
        contentText: 'Avaliação ja foi aprovada!',
      }),
    );
  }
}

export function* watchSagas() {
  yield takeLatest(RatingTypes.LIST_RATING, listRating);
  yield takeLatest(RatingTypes.GET_RATING, getRating);
  yield takeLatest(RatingTypes.ACTIVATE_RATING, activateRating);
}
