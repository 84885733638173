import React from 'react';
import Input from 'components/core/form/Input';
import Button from 'components/core/Button';
import HandleMessage from 'components/core/HandleMessage';
import {useForm} from 'react-hook-form';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import Loading from 'components/core/Loading';
import * as S from './Form.styles';
import {GET_ERROR} from 'constants/messages';

const Form = ({
  admin,
  submitAction,
  error,
  isPending,
  isReject,
  refForm,
  buttonText,
  validationSchema,
}) => {
  const resolver = useYupValidationResolver(validationSchema);

  const {register, handleSubmit, errors, reset} = useForm({
    resolver,
    defaultValues: admin,
  });
  const submit = (data) => {
    submitAction(data);
  };

  return (
    <S.Form onSubmit={handleSubmit(submit)} ref={refForm} onReset={reset}>
      {isReject ? (
        <S.FormGroup>
          <HandleMessage type="error">
            {GET_ERROR(error?.data?.message[0])}
          </HandleMessage>
        </S.FormGroup>
      ) : null}
      <Input
        label="Nome"
        placeholder="Informe o nome"
        name="name"
        inputRef={register}
        errors={errors.name && errors.name.message}
      />

      <Input
        label="Email de acesso"
        placeholder="Informe o email"
        name="email"
        inputRef={register}
        errors={errors.email && errors.email.message}
      />
      <Input
        autocomplete="current-password"
        label="Senha"
        type="password"
        placeholder="Informe a senha"
        name="password"
        inputRef={register}
        errors={errors.name && errors.name.message}
      />
      <Button type="submit" color="primary" disabled={isPending}>
        {isPending ? <Loading /> : buttonText}
      </Button>
    </S.Form>
  );
};
Form.defaultProps = {
  admin: {
    name: '',
    lastName: '',
    email: '',
  },
  isPending: false,
  buttonText: '',
  isReject: false,
  submitAction: () => {},
};
export default Form;
