import React, {useCallback} from 'react';
import Table from 'components/core/Table';
import * as S from './TableRatings.styles';
import {useDispatch, useSelector} from 'react-redux';

import {Search} from 'components/core/form/Search';
import SelectInput from 'components/core/form/SelectInput';

import LoadingFullScreen from 'components/core/LoadingFullScreen/';
import {isPending} from 'lib/core/request';
import {RatingActions} from 'store/rating/rating.ducks';
import Switch from 'react-switch';
import {getStars} from 'lib/core/utilityView';
import styled from 'styled-components';
import {formatDate} from 'lib/core/utility';

export const CellCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableRatings = () => {
  const dispatch = useDispatch();
  const {
    listRating: {
      items,
      meta: {totalPages, itemsPerPage, currentPage},
    },
    ratingRequests,
  } = useSelector(({rating}) => rating);
  const onChangeSearch = (e) => {
    dispatch(
      RatingActions.listRating({
        name: e.target.value,
        limit: 10,
        page: 1,
      }),
    );
  };
  const onChangeStatus = (e) => {
    dispatch(
      RatingActions.listRating({
        isActive: e.target.value,
        limit: 10,
        page: 1,
      }),
    );
  };

  const fetchData = useCallback(
    ({pageSize, pageIndex}) => {
      dispatch(
        RatingActions.listRating({
          limit: pageSize,
          page: pageIndex + 1,
        }),
      );
    },
    [dispatch],
  );
  const options = [
    {
      label: 'Filtrar por status',
      value: '',
    },
    {
      label: 'Ativados',
      value: true,
    },
    {
      label: 'Pendentes',
      value: false,
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nome da empresa',
        accessor: 'bussiness.name',
        width: 350,
      },
      {
        Header: 'Avaliador',
        accessor: 'user.name',
      },
      {
        Header: 'Serviço',
        accessor: 'serviceRating',
        Cell: ({value}) => {
          return <CellCenter>{getStars(value)}</CellCenter>;
        },
      },
      {
        Header: 'Atendimento',
        accessor: 'attendanceRating',
        Cell: ({value}) => {
          return <CellCenter>{getStars(value)}</CellCenter>;
        },
      },
      {
        Header: 'Comunicação',
        accessor: 'communicationRating',
        Cell: ({value}) => {
          return <CellCenter>{getStars(value)}</CellCenter>;
        },
      },
      {
        Header: 'Média Total',
        accessor: 'bussiness.avgRating',
        Cell: ({value}) => {
          return <CellCenter>{getStars(value)}</CellCenter>;
        },
      },
      {
        Header: 'Registrado em',
        accessor: 'createAt',
        minWidth: 150,
        Cell: ({value}) => formatDate(value, 'dd/mm/yy HH:MM'),
      },
      {
        Header: 'Ativado',
        accessor: 'isActive',
        width: 30,
        Cell: (props) => {
          const id = props.cell.row.original.id;

          return (
            <CellCenter>
              <Switch
                onChange={(e) => {
                  dispatch(
                    RatingActions.activateRating(
                      {
                        params: id,
                        data: {isActive: e},
                      },
                      () =>
                        dispatch(
                          RatingActions.listRating({
                            limit: itemsPerPage,
                            page: currentPage,
                          }),
                        ),
                    ),
                  );
                }}
                checked={props.value}
              />
            </CellCenter>
          );
        },
      },
    ],
    [itemsPerPage, currentPage, dispatch],
  );

  return (
    <S.TableContent>
      {isPending(ratingRequests.LIST_RATING) && <LoadingFullScreen />}
      <S.Header>
        <S.Title> Todos as avaliações </S.Title>
        <S.FilterContainer>
          <Search placeholder="Buscar empresas" onChange={onChangeSearch} />
          <S.FilterSelect>
            <SelectInput options={options} onChange={onChangeStatus} />
          </S.FilterSelect>
        </S.FilterContainer>
      </S.Header>
      <Table
        columns={columns}
        data={items}
        pageCount={totalPages}
        fetchData={fetchData}
        showTitle="avaliações"
      />
    </S.TableContent>
  );
};

export default TableRatings;
