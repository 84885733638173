import {all} from 'redux-saga/effects';
import * as authSagas from './auth/auth.sagas';
import * as userSagas from './user/user.sagas';
import * as bussinessSagas from './bussiness/bussiness.sagas';
import * as ratingSagas from './rating/rating.sagas';
import * as dashboardSagas from './dashboard/dashboard.sagas';
import * as adminSagas from './admin/admin.sagas';

function* Sagas() {
  yield all([
    authSagas.watchSagas(),
    userSagas.watchSagas(),
    bussinessSagas.watchSagas(),
    ratingSagas.watchSagas(),
    dashboardSagas.watchSagas(),
    adminSagas.watchSagas(),
  ]);
}

export default Sagas;
