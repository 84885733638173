import React from 'react';
import * as S from './CardInfo.styles';
import Card from 'components/core/Card';

const CardInfo = ({children}) => {
  return (
    <S.CardContainer>
      <Card>
        <S.CardContent>
          <S.AlignCenter>{children}</S.AlignCenter>
        </S.CardContent>
      </Card>
    </S.CardContainer>
  );
};

export default CardInfo;
