import styled from "styled-components";
import tokens from "assets/styles/tokens";

export const InputContainer = styled.div`
  margin-top: ${tokens.spacing.lg};
  margin-bottom: ${tokens.spacing.lg};
`;
export const Label = styled.label`
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin-bottom: ${tokens.spacing.sm};
  color: ${tokens.colors.black01};
`;
export const AlignEnd = styled.div`
  text-align: end;
`;
export const Required = styled.span`
  color: red;
  font-size: 14px;
`;

export const InputContent = styled.input`
  display: block;
  width: 100%;
  height: 50px;
  padding: 16px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: transparent;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 12px;
  &::placeholder {
    color: ${tokens.colors.black03};
  }
`;
