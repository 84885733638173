import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const Name = styled.figcaption`
  width: 80%;
`;
export const Avatar = styled.div`
  width: 30px;
  height: 30px;
  background-size: cover;
  margin-right: 8px;
  border-radius: 4px;
  ${({url}) => url && `background-image: url(${url}),url('/avatar.png');`}
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const New = styled.div`
  position: absolute;
  left: 0px;
  background-color: ${tokens.colors.orange01};
  border-radius: 6px;
  color: ${tokens.colors.white};
  padding: 4px 5px;
  font-size: 11px;
  font-family: ${tokens.fonts.bold};
`;
