/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {ReactComponent as IconClose} from 'assets/icons/icCloseRound.svg';
import * as S from './Modal.styles';

const Modal = ({children, collapse, width, height}) => {
  const [fade, setFade] = useState(collapse);
  useEffect(() => {
    if (collapse) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    setFade(collapse);
  }, [collapse]);
  return (
    <>
      <S.ModalBackdrop open={collapse} />
      <S.Modal open={collapse}>
        <S.ModalDialog fade={fade} width={width} height={height}>
          <S.ModalBody>{children}</S.ModalBody>
        </S.ModalDialog>
      </S.Modal>
    </>
  );
};
Modal.defaultProps = {
  collapse: false,
  callBack: () => {},
  children: () => {},
};
export default Modal;
