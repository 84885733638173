/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {renderToStaticMarkup} from 'react-dom/server';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import searchIcon from 'assets/icons/search.svg';

export const Search = styled.input`
  background: #ffffff;
  max-height: 40px;
  padding: 8px 14px 8px 36px;
  border: 1px solid #eeeef0;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  ::placeholder {
    color: #d6d9dc;
    opacity: 1;
  }
  background-size: 14px;
  background-image: url('${searchIcon}');
  background-position: 12px 10px;
  background-repeat: no-repeat;
  width: 100%;
`;
