import React from 'react';
import * as S from './NavbarDefault.styles';
import LogoIcon from 'assets/images/logo-nav.svg';
import DropdownNav from './DropdownNav';
import {GET} from 'lib/core/localStorage';
import jwt from 'jsonwebtoken';
import {AuthActions} from 'store/auth/auth.ducks';
import {history} from 'constants/routes';
import urls from 'constants/urls';
import {useDispatch} from 'react-redux';

const NavbarDefault = ({active}) => {
  const {token} = GET('access-token');
  const data = jwt.decode(token);
  const {name} = data;
  const dispatch = useDispatch();
  const signOut = () => {
    dispatch(AuthActions.signOut());
  };
  const menuItens = [
    {
      key: '1',
      handleClick: signOut,
      name: 'Sair',
    },
  ];

  return (
    <S.NavTop>
      <S.LogoContainer>
        <S.Logo src={LogoIcon} alt="Logo" />
      </S.LogoContainer>
      <S.MenuContainer>
        <S.TopNav>
          <S.Menu>
            <S.Item
              className={active === 'users' ? 'active' : ''}
              onClick={() => history.push(urls.LINKS.APP)}>
              Usuários
            </S.Item>
          </S.Menu>
          <S.Menu>
            <S.Item
              className={active === 'bussiness' ? 'active' : ''}
              onClick={() => history.push(urls.LINKS.BUSSINESS)}>
              Escritórios
            </S.Item>
          </S.Menu>
          <S.Menu>
            <S.Item
              className={active === 'ratings' ? 'active' : ''}
              onClick={() => history.push(urls.LINKS.RATINGS)}>
              Avaliações
            </S.Item>
          </S.Menu>
          <S.Menu>
            <S.Item
              className={active === 'admin' ? 'active' : ''}
              onClick={() => history.push(urls.LINKS.ADMIN)}>
              Admininstradores
            </S.Item>
          </S.Menu>
        </S.TopNav>
      </S.MenuContainer>
      <S.UserContainer>
        <DropdownNav userType="" username={name} menuItens={menuItens} />
      </S.UserContainer>
    </S.NavTop>
  );
};

export default NavbarDefault;
