import React from 'react';
import * as S from './CardProfile.styles';

const CardProfileDataContent = ({children}) => {
  return <S.CardProfileDataContent>{children}</S.CardProfileDataContent>;
};
CardProfileDataContent.defaultProps = {
  onClick: () => {},
};
export default CardProfileDataContent;
