import React from 'react';
import Modal from 'components/core/Modal';

import Button from 'components/core/Button';
import {useDispatch} from 'react-redux';

import {AdminActions} from 'store/admin/admin.ducks';
import * as S from './DeleteAdmin.styles';

const DeleteAdmin = ({collapse, callBack, data}) => {
  const dispatch = useDispatch();
  const clear = () => {
    dispatch(
      AdminActions.listAdmin({
        name: '',
        limit: 10 * 2,
        page: 1,
      }),
    );
  };
  const submit = () => {
    const {id} = data;
    dispatch(AdminActions.deleteAdmin(id, clear));
    callBack();
  };
  return (
    <Modal collapse={collapse}>
      <S.Content>
        <S.Header>Excluir administrador</S.Header>

        <S.Title>
          Você tem certeza que deseja deletar esse administrador?
        </S.Title>
        <S.ButtonContainer>
          <Button
            type="submit"
            color="secondary"
            padding="14px 14px"
            onClick={callBack}>
            Cancelar
          </Button>
          <Button
            type="submit"
            color="primary"
            padding="14px 14px"
            onClick={submit}>
            Excluir administrador
          </Button>
        </S.ButtonContainer>
      </S.Content>
    </Modal>
  );
};
DeleteAdmin.defaultProps = {
  collapse: false,
  adminItem: {
    name: 'hernandes',
  },
  callBack: () => {},
};
export default DeleteAdmin;
