/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import * as S from './CellAvatar.styles';
import {getAvatar} from 'lib/contexts/users';

const CellTable = ({cell: {value}, row, onClick}) => {
  const {original} = row;
  return (
    <S.AvatarContainer onClick={() => onClick(original)}>
      {/* {value.new && <S.New>NOVO</S.New>} */}
      <S.Avatar url={getAvatar(original)} />
      <S.Name>{original.name}</S.Name>
    </S.AvatarContainer>
  );
};

CellTable.defaultProps = {
  cell: {
    value: '',
  },
  onClick: () => {},
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CellTable;
