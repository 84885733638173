import React, {useEffect} from 'react';
import * as S from './Dashboard.styles';
import {useDispatch, useSelector} from 'react-redux';

import {Row} from 'components/structure/Row';
import {Col} from 'components/structure/Col';
import {CardInfo, CardTitle, CardDescription} from 'components/core/CardInfo';
import {DashboardActions} from 'store/dashboard/dashboard.ducks';

const Dashboard = () => {
  const dispatch = useDispatch();
  const {data} = useSelector(({dashboard}) => dashboard);
  useEffect(() => {
    dispatch(DashboardActions.getDashboards());
  }, [dispatch]);
  return (
    <S.DashboardContent>
      <S.Header>
        <S.Title>Dashboard</S.Title>
      </S.Header>
      <S.WrapperCard>
        <Row>
          <Col desktop="2" mobile="12">
            <CardInfo>
              <CardTitle color="blue">{data?.countTotal}</CardTitle>
              <CardDescription>Total de usuários</CardDescription>
            </CardInfo>
          </Col>
          <Col desktop="2" mobile="12">
            <CardInfo>
              <CardTitle color="blue">{data?.countBussiness}</CardTitle>
              <CardDescription>Escritórios cadastradas</CardDescription>
            </CardInfo>
          </Col>
          <Col desktop="2" mobile="12">
            <CardInfo>
              <CardTitle color="blue">{data?.countUser}</CardTitle>
              <CardDescription>Usuários cadastrados</CardDescription>
            </CardInfo>
          </Col>
          <Col desktop="2" mobile="12">
            <CardInfo>
              <CardTitle color="blue">{data?.countRatings}</CardTitle>
              <CardDescription>Avaliações</CardDescription>
            </CardInfo>
          </Col>
          <Col desktop="2" mobile="12">
            <CardInfo>
              <CardTitle color="blue">{data?.countVisits}</CardTitle>
              <CardDescription>Escritórios visitados</CardDescription>
            </CardInfo>
          </Col>
          <Col desktop="2" mobile="12">
            <CardInfo>
              <CardTitle color="blue">{data?.countContacts}</CardTitle>
              <CardDescription>Escritórios contactados</CardDescription>
            </CardInfo>
          </Col>
        </Row>
      </S.WrapperCard>
    </S.DashboardContent>
  );
};

export default Dashboard;
