/* eslint-disable no-dupe-keys */
import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const Content = styled.div`
  margin-top: 73px;
  margin-left: auto;
  margin-right: auto;
  ${tokens.media.xs} {
    margin-top: 0px;
  }
`;
