import React from 'react';
import * as S from './CardProfile.styles';
import Card from 'components/core/Card';

const CardProfile = ({children}) => {
  return (
    <S.CardContainer>
      <Card>
        <S.CardContent>{children}</S.CardContent>
      </Card>
    </S.CardContainer>
  );
};

export default CardProfile;
