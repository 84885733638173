import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

const Link = styled.span`
  font-weight: bold;
  cursor: pointer;
  color: ${tokens.colors.primaryColor};
  text-decoration: none;
`;
export default Link;
