/* eslint-disable consistent-return */
/* eslint-disable no-dupe-keys */
import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

const getWidthGrid = (value) => {
  if (!value) return;
  const width = (value / 12) * 100;
  return `width: ${width}%;`;
};
export const Col = styled.div`
  float: left;
  padding: ${tokens.spacing.xs};
  min-height: 1px;
  box-sizing: border-box;
  ${tokens.media.mobile} {
    ${({mobile}) => mobile && getWidthGrid(mobile)}
  }
  ${tokens.media.tablet} {
    ${({tablet}) => tablet && getWidthGrid(tablet)}
  }
  ${tokens.media.desktop} {
    ${({desktop}) => desktop && getWidthGrid(desktop)}
  }
`;
