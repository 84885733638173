import axios from 'axios';
import * as urlsApi from 'constants/endpoints';
import {REMOVE, GET} from 'lib/core/localStorage';
import {history} from 'constants/routes';
import urls from 'constants/urls';

const api = axios.create({
  baseURL: urlsApi.basePath,
  headers: {
    'Content-Type': 'application/json',
  },
});
api.interceptors.request.use(
  async (request) => {
    request.headers.common['Content-Type'] = 'application/json';
    const {token} = GET('access-token');
    if (token) {
      const accessToken = `Bearer ${token}`;
      request.headers.common.Authorization = accessToken;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const {
      response: {status},
    } = error;
    if (status === 401) {
      REMOVE('access-token');
      history.push(urls.ROUTES.LOGIN);
      throw error;
    }
    return Promise.reject(error);
  },
);
export const apiMock = axios.create({
  baseURL: urlsApi.baseMock,
  headers: {
    'Content-Type': 'application/json',
  },
});
export default api;
