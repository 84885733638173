const urls = {
  ROUTES: {
    APP: '/',
    LOGIN: '/login',
    DASHBOARD: '/',
    USERS: '/usuarios/:id',
    RATINGS: '/avaliacoes',
    CREATE_RATING: '/avaliacoes/new',
    EDIT_RATING: '/avaliacoes/:id/edit',
    BUSSINESS: '/escritorios',
    SHOW_BUSSINESS: '/escritorios/:id',
    ADMIN: '/admin',
    SHOW_ADMIN: '/admin/:id',
  },
  LINKS: {
    APP: '/',
    LOGIN: '/login',
    DASHBOARD: '/',
    RATINGS: '/avaliacoes',
    CREATE_RATING: '/avaliacoes/new',
    EDIT_RATING: (id) => `/avaliacoes/${id}/edit`,
    BUSSINESS: '/escritorios',
    ADMIN: '/admin',
    SHOW_BUSSINESS: (id) => `/escritorios/${id}`,
    SHOW_ADMIN: (id) => `/admin/${id}`,
    SHOW_USERS: (id) => `/usuarios/${id}`,
  },
};

export default urls;
