import React from 'react';
import * as S from './CardProfile.styles';

const CardProfileData = ({
  icon,
  name,
  bgIconColor,
  children,
  iconComponent,
}) => {
  return (
    <S.CardProfileData>
      {icon && (
        <S.IconContent bgIconColor={bgIconColor}>
          {iconComponent ? icon : <S.Icon src={icon} alt="Icone" />}
        </S.IconContent>
      )}
      <S.ColorText>
        <strong>{name}: </strong>
        {children}
      </S.ColorText>
    </S.CardProfileData>
  );
};

export default CardProfileData;
