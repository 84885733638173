import styled from 'styled-components';
import css from '@styled-system/css';
import {compose, space, flexbox} from 'styled-system';
import tokens from 'assets/styles/tokens';

export const Container = styled('div')(
  css({
    display: 'flex',
  }),
  compose(space, flexbox),
);

export const PageNavigation = styled('button')(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bg: tokens.colors.white,
    border: '1px solid',
    borderColor: tokens.colors.white,
    marginLeft: '1px',
    marginRight: '1px',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      bg: tokens.colors.white,
    },
    '&:focus': {
      outline: 'none',
      zIndex: 1,
    },
  }),
);

export const PageNumber = styled(PageNavigation)(
  css({
    border: '1px solid #EEEEF0',
    marginLeft: '4px',
    marginRight: '4px',
    color: tokens.colors.primaryHeaderColor,
  }),
  ({unnumbered}) =>
    unnumbered &&
    css({
      width: '8px',
      height: '8px',
      bg: tokens.colors.white,
      opacity: 0.2,
      '&:not(:last-of-type)': {
        mr: '8px',
      },
      '&:hover': {
        bg: tokens.colors.white,
        opacity: 0.6,
      },
    }),
  ({unnumbered, selected}) =>
    unnumbered &&
    selected &&
    css({
      boxShadow: 'none',
      opacity: 1,
      '&:hover': {
        opacity: 0.6,
      },
      '&:focus': {
        boxShadow: 'none',
      },
    }),
  ({unnumbered, selected}) =>
    !unnumbered &&
    selected &&
    css({
      fontWeight: 'bold',
      bg: tokens.colors.primaryHeaderColor,
      color: tokens.colors.white,
      borderColor: tokens.colors.primaryHeaderColor,
      '&:hover': {
        bg: tokens.colors.primaryHeaderColor,
        color: tokens.colors.whiteLight,
      },
    }),
);
