import React from 'react';
import * as S from './CardInfo.styles';

const CardTitle = ({children, color, fontSize}) => {
  return (
    <S.CardTitle color={color} fontSize={fontSize}>
      {children}
    </S.CardTitle>
  );
};
CardTitle.defaultProps = {
  color: null,
};
export default CardTitle;
