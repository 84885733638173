import React from 'react';
import * as S from './CardProfile.styles';

const CardProfileInfo = ({color, children}) => {
  return (
    <S.AlignCenter>
      <S.ProfileInfo color={color}>{children}</S.ProfileInfo>
    </S.AlignCenter>
  );
};

export default CardProfileInfo;
