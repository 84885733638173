/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */

export const serialize = (obj, prefix) => {
  const str = [];
  let p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? `${prefix}[${p}]` : p;
      const v = obj[p];
      str.push(
        v !== null && typeof v === 'object'
          ? serialize(v, k)
          : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
      );
    }
  }
  return str.join('&');
};
export const formatDate = (date, format = 'dd/mm/yy') => {
  const dateObject = new Date(date);
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();

  return !date
    ? '-'
    : format
        .replace('dd', day)
        .replace('mm', month)
        .replace('yyyy', year)
        .replace('yy', year.toString().substring(2, 4))
        .replace('HH', hours)
        .replace('MM', minutes);
};

export const getTranslateWeek = () => {
  return {
    monday: 'Segunda',
    tuesday: 'Terça',
    wednesday: 'Quarta',
    thursday: 'Quinta',
    friday: 'Sexta',
    saturday: 'Sábado',
    sunday: 'Domingo',
  };
};
export const getWeekNumber = () => {
  return {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };
};
export const getWeekName = () => {
  return {
    0: 'Domingo',
    1: 'Segunda',
    2: 'Terça',
    3: 'Quarta',
    4: 'Quinta',
    5: 'Sexta',
    6: 'Sábado',
  };
};

export const formatHour = (hour) => {
  const auxHour = hour.toString().match(/.{1,2}/g);
  return `${auxHour[0]}:${auxHour[1]}`;
};
export const getTranslateTime = () => {
  return {
    morning: 'Manha',
    afternoon: 'Tarde',
    night: 'Noite',
  };
};
export const getWeekMonth = () => {
  const d = new Date();
  const date = d.getDate();
  const day = d.getDay();

  const weekOfMonth = Math.ceil((date - 1 - day) / 7);
  return weekOfMonth + 1;
};

export const getValue = (value) => {
  return value || '-';
};

export const getOnlyDate = (date) => {
  const newDate = date.toString().split('T')[0];
  return newDate;
};
