import React from 'react';
import * as S from './CardInfo.styles';

const CardDescription = ({children, color}) => {
  return <S.CardDescription color={color}>{children}</S.CardDescription>;
};
CardDescription.defaultProps = {
  color: null,
};
export default CardDescription;
