/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo} from 'react';
import * as S from '../LoginForm.styles';

import Input from 'components/core/form/Input';
import Button from 'components/core/Button';
import Link from 'components/core/Link';
import {useDispatch} from 'react-redux';
import {history} from 'constants/routes';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import {AuthActions} from 'store/auth/auth.ducks';
import {isPending, isReject} from 'lib/core/request';

const Forgot = ({setForm}) => {
  const dispatch = useDispatch();

  const validationSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .required('Campo de e-mail obrigatório')
          .email('Digite um e-mail válido'),
      }),
    [],
  );
  const resolver = useYupValidationResolver(validationSchema);

  const {register, handleSubmit, errors, clearErrors} = useForm({resolver});

  const forgotPassword = (data) => {
    dispatch(AuthActions.forgotPassword(data));
  };

  return (
    <S.FormLogin onSubmit={handleSubmit(forgotPassword)}>
      <S.Title>
        Informe o seu <b>email cadastrado </b>para enviarmos sua nova senha
      </S.Title>
      <Input
        label="Seu e-mail"
        placeholder="Informe seu email"
        defaultValue=""
        name="email"
        inputRef={register}
        errors={errors.email && errors.email.message}
      />

      <S.FormGroup>
        <Link
          onClick={() => {
            setForm('login');
            clearErrors();
          }}>
          Voltar
        </Link>
      </S.FormGroup>
      <Button type="submit" color="primary">
        Continuar
      </Button>
    </S.FormLogin>
  );
};

export default Forgot;
