import React from 'react';
import styled from 'styled-components';
import Table from 'components/core/Table';
import * as S from './TableBussinessContacts.styles';
import CellTable from './CellAvatar/CellAvatar';
import {history} from 'constants/routes';
import urls from 'constants/urls';
import {formatDate} from 'lib/core/utility';

export const CellCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableBussinessContacts = ({data}) => {
  const onClickRow = (row) => {
    const {id} = row;
    history.push(urls.LINKS.USERS(id));
  };

  const dataFormat = data.map((d) => ({
    name: d.user.name,
    email: d.user.email,
    createAt: d.createAt,
  }));

  const columns = [
    {
      Header: 'Nome',
      accessor: 'name',
      width: 250,
      Cell: (props) => <CellTable {...props} onClick={onClickRow} />,
    },
    {
      Header: 'Email',
      accessor: 'email',
      minWidth: 200,
    },
    {
      Header: 'Contactado em',
      accessor: 'createAt',
      minWidth: 150,
      Cell: ({value}) => formatDate(value, 'dd/mm/yy HH:MM'),
    },
  ];

  return (
    <S.TableContent>
      <S.Header>
        <S.Title> Contactados </S.Title>
      </S.Header>
      {data && (
        <Table columns={columns} data={dataFormat} showTitle="contactados" />
      )}
    </S.TableContent>
  );
};

export default TableBussinessContacts;
