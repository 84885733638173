import {takeLatest, put} from 'redux-saga/effects';
import api from 'services/api';
import {
  RESOURCE_ADMIN,
  RESOURCE_ADMIN_ACTIVATE,
  DELETE_ADMIN,
} from 'constants/endpoints';
import {GET, POST, PUT, DELETE} from 'constants/verbs';
import {serialize} from 'lib/core/utility';
import {AdminActions, AdminTypes} from './admin.ducks';
import {MessagesActions} from '../message/message.ducks';
export function* listAdmin({params}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_ADMIN}?${serialize(params)}`,
    });
    yield put(AdminActions.listAdminSuccess(response.data));
  } catch (error) {
    yield put(AdminActions.adminRequestFailure(error));
  }
}

export function* getAdmin({params}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_ADMIN(params)}`,
    });

    yield put(AdminActions.getAdminSuccess(response.data));
  } catch (error) {
    yield put(AdminActions.adminRequestFailure(error));
  }
}

export function* activateAdmin({payload, callback}) {
  const {params, data} = payload;
  try {
    yield api({
      method: POST,
      url: `${RESOURCE_ADMIN_ACTIVATE(params)}`,
      data: data,
    });

    yield put(AdminActions.activateAdminSuccess());
    yield callback();
  } catch (error) {
    yield put(AdminActions.adminRequestFailure(error));
  }
}
export function* deleteAdmin({payload, callback}) {
  try {
    yield api({
      method: DELETE,
      url: `${DELETE_ADMIN(payload)}`,
    });

    yield put(AdminActions.deleteAdminSuccess());
    yield callback();
    yield put(
      MessagesActions.open({
        type: 'success',
        title: 'Admin',
        contentText: 'Admin deletado com sucesso!',
      }),
    );
  } catch (error) {
    yield put(
      MessagesActions.open({
        type: 'error',
        title: 'Admin',
        contentText: 'Falha ao deletar o admin!',
      }),
    );
    yield put(AdminActions.adminRequestFailure(error));
  }
}
export function* createAdmin({payload, callback}) {
  try {
    yield api({
      method: POST,
      url: `${RESOURCE_ADMIN}`,
      data: payload,
    });

    yield put(AdminActions.createAdminSuccess());
    yield callback();
    yield put(
      MessagesActions.open({
        type: 'success',
        title: 'Admin',
        contentText: 'Admin criado com sucesso!',
      }),
    );
  } catch (error) {
    yield put(AdminActions.adminRequestFailure(error));
  }
}
export function* updateAdmin({payload, callback}) {
  try {
    yield api({
      method: PUT,
      url: `${RESOURCE_ADMIN}`,
      data: payload,
    });

    yield put(AdminActions.updateAdminSuccess());
    yield callback();
    yield put(
      MessagesActions.open({
        type: 'success',
        title: 'Admin',
        contentText: 'Admin atualizado com sucesso!',
      }),
    );
  } catch (error) {
    yield put(AdminActions.adminRequestFailure(error));
  }
}
export function* watchSagas() {
  yield takeLatest(AdminTypes.LIST_ADMIN, listAdmin);
  yield takeLatest(AdminTypes.CREATE_ADMIN, createAdmin);
  yield takeLatest(AdminTypes.UPDATE_ADMIN, updateAdmin);
  yield takeLatest(AdminTypes.DELETE_ADMIN, deleteAdmin);
  yield takeLatest(AdminTypes.GET_ADMIN, getAdmin);
  yield takeLatest(AdminTypes.ACTIVATE_ADMIN, activateAdmin);
}
