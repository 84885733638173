import React from 'react';
import SelectInput from 'components/core/form/SelectInput';
import Button from 'components/core/Button';
import HandleMessage from 'components/core/HandleMessage';
import {useForm} from 'react-hook-form';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import Loading from 'components/core/Loading';
import * as S from './Form.styles';
import {GET_ERROR} from 'constants/messages';

const Form = ({
  business,
  submitAction,
  error,
  isPending,
  isReject,
  refForm,
  buttonText,
  validationSchema,
}) => {
  const options = [
    {
      label: 'Selecione o tamanho do escritório',
      value: '',
    },
    {
      label: 'Pessoa Física',
      value: 'naturalperson',
    },
    {
      label: 'Escritórios com até 5 advogados',
      value: 'smalloffice',
    },
    {
      label: 'Escritórios com mais de 5 advogados',
      value: 'bigoffice',
    },
  ];
  const resolver = useYupValidationResolver(validationSchema);

  const {register, handleSubmit, errors, reset} = useForm({
    resolver,
    defaultValues: business,
  });
  const submit = (data) => {
    submitAction(data);
  };

  return (
    <S.Form onSubmit={handleSubmit(submit)} ref={refForm} onReset={reset}>
      {isReject ? (
        <S.FormGroup>
          <HandleMessage type="error">
            {GET_ERROR(error?.data?.message[0])}
          </HandleMessage>
        </S.FormGroup>
      ) : null}
      <S.FormGroup>
        <SelectInput
          label="Tamanho do escritório"
          placeholder="Informe o tamanho"
          options={options}
          name="businessType"
          inputRef={register}
          errors={errors.businessType && errors.businessType.message}
        />
      </S.FormGroup>
      <Button type="submit" color="primary" disabled={isPending}>
        {isPending ? <Loading /> : buttonText}
      </Button>
    </S.Form>
  );
};
Form.defaultProps = {
  business: {
    name: '',
    lastName: '',
    email: '',
  },
  isPending: false,
  buttonText: '',
  isReject: false,
  submitAction: () => {},
};
export default Form;
