import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import Table from 'components/core/Table';
import * as S from './TableBussiness.styles';
import {useDispatch, useSelector} from 'react-redux';
import CellTable from './CellAvatar/CellAvatar';
import {Search} from 'components/core/form/Search';
import {FaDollarSign} from 'react-icons/fa';
import LoadingFullScreen from 'components/core/LoadingFullScreen/';
import {isPending} from 'lib/core/request';
import {history} from 'constants/routes';
import urls from 'constants/urls';
import {BussinessActions} from 'store/bussiness/bussiness.ducks';
import SelectInput from 'components/core/form/SelectInput';
import {BsEye} from 'react-icons/bs';
import {getStars} from 'lib/core/utilityView';
import EditBussiness from '../EditBussiness';

export const CellCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableBussiness = () => {
  const [modalOpen, setModalOpen] = useState(null);
  const [bussinessItem, setBussinessItem] = useState(null);

  const setModal = useCallback(
    (modal) => {
      if (modal === modalOpen) {
        setModalOpen(null);
      } else {
        setModalOpen(modal);
      }
    },
    [setModalOpen, modalOpen],
  );
  const closeModal = () => {
    setModalOpen(null);
  };
  const dispatch = useDispatch();
  const {
    listBussiness: {
      items,
      meta: {totalPages},
    },
    bussinessRequests,
  } = useSelector(({bussiness}) => bussiness);

  const setEditBussiness = useCallback(
    (bussiness) => {
      setBussinessItem(bussiness);
      setModal('EditBussiness');
    },
    [setBussinessItem, setModal],
  );

  const onChangeSearch = (e) => {
    dispatch(
      BussinessActions.listBussiness({
        name: e.target.value,
        limit: 10 * 2,
        page: 1,
        orderBy: 'createAt',
        orderDirection: 'DESC',
      }),
    );
  };

  const options = [
    {
      label: 'Filtrar por status',
      value: '',
    },
    {
      label: 'Ativados',
      value: true,
    },
    {
      label: 'Pendentes',
      value: false,
    },
  ];

  const orderBy = [
    {
      label: 'Ordenar por',
      value: 'createAt',
    },
    {
      label: 'Nome',
      value: 'name',
    },
    {
      label: 'Visitas',
      value: 'amountVisits',
    },
  ];

  const fetchData = useCallback(
    ({pageSize, pageIndex}) => {
      dispatch(
        BussinessActions.listBussiness({
          limit: pageSize * 2,
          page: pageIndex + 1,
          orderBy: 'createAt',
          orderDirection: 'DESC',
        }),
      );
    },
    [dispatch],
  );

  const onChangeStatus = (e) => {
    dispatch(
      BussinessActions.listBussiness({
        isActive: e.target.value,
        limit: 10,
        page: 1,
        orderBy: 'createAt',
        orderDirection: 'DESC',
      }),
    );
  };

  const onChangeOrder = (e) => {
    dispatch(
      BussinessActions.listBussiness({
        limit: 10,
        page: 1,
        orderBy: e.target.value,
        orderDirection: e.target.value !== 'name' ? 'DESC' : 'ASC',
      }),
    );
  };

  const onClickRow = (row) => {
    const {id} = row;
    history.push(urls.LINKS.USERS(id));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nome da empresa',
        accessor: 'name',
        width: 250,
        Cell: (props) => <CellTable {...props} onClick={onClickRow} />,
      },
      {
        Header: 'OAB',
        accessor: 'oab',
        minWidth: 10,
        Cell: ({value}) => {
          return value ? value : 'Não informado.';
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 150,
      },
      {
        Header: 'Cidade',
        accessor: 'city',
        minWidth: 120,
        Cell: (props) => {
          return (
            <span>
              {props.value && props.value !== 'undefined'
                ? `${props.value.name} - ${props.value.prefixState}`
                : '-'}
            </span>
          );
        },
      },
      {
        Header: 'Visitas',
        accessor: 'amountVisits',
        width: 10,
        maxWidth: 10,
        textAlign: 'center',
      },
      {
        Header: 'Contactados',
        accessor: 'bussinessContacts',
        width: 10,
        maxWidth: 10,
        textAlign: 'center',
        Cell: ({value}) => value.length,
      },
      {
        Header: 'Avaliação',
        accessor: 'avgRating',
        minWidth: 100,
        Cell: ({value}) => {
          return <CellCenter>{getStars(value)}</CellCenter>;
        },
      },
      {
        Header: 'Status',
        accessor: 'isActive',
        minWidth: 100,
        Cell: ({value}) => {
          return (
            <CellCenter>
              <S.Pill color={value ? 'green' : 'red'}>
                {value ? 'Ativo' : 'Desativado'}
              </S.Pill>
            </CellCenter>
          );
        },
      },
      {
        Header: 'Ações',
        accessor: 'action',
        minWidth: 60,
        Cell: (props) => {
          const data = props.cell.row.original;
          return (
            <CellCenter>
              <BsEye
                style={{width: '24px', height: '24px', cursor: 'pointer'}}
                onClick={() => history.push(urls.LINKS.SHOW_BUSSINESS(data.id))}
              />
              <FaDollarSign
                style={{
                  width: '24px',
                  height: '24px',
                  cursor: 'pointer',
                  marginLeft: '4px',
                }}
                onClick={() => setEditBussiness(data)}
              />
            </CellCenter>
          );
        },
      },
    ],
    [setEditBussiness],
  );

  return (
    <S.TableContent>
      {isPending(bussinessRequests.LIST_BUSSINESS) && <LoadingFullScreen />}
      <S.Header>
        <S.Title> Todos as escritórios </S.Title>
        <S.FilterContainer>
          <Search placeholder="Buscar escritórios" onChange={onChangeSearch} />
          <S.FilterSelect>
            <SelectInput options={options} onChange={onChangeStatus} />
          </S.FilterSelect>
          <S.FilterSelect>
            <SelectInput options={orderBy} onChange={onChangeOrder} />
          </S.FilterSelect>
        </S.FilterContainer>
      </S.Header>
      <Table
        columns={columns}
        data={items}
        pageCount={totalPages}
        fetchData={fetchData}
        showTitle="escritórios"
      />
      <EditBussiness
        collapse={modalOpen === 'EditBussiness'}
        data={bussinessItem}
        callBack={closeModal}
      />
    </S.TableContent>
  );
};

export default TableBussiness;
