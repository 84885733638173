import {
  REQUEST_PENDING,
  REQUEST_NOT_STARTED,
  REQUEST_REJECTED,
} from 'constants/request';

export const isPending = (status) => status === REQUEST_PENDING;

export const isNotInit = (status) => status === REQUEST_NOT_STARTED;

export const isReject = (status) => status === REQUEST_REJECTED;

export const isInProgress = (status) => isPending(status) || isNotInit(status);

export const isFinished = (status) => !isPending(status) && !isNotInit(status);
